<template>
    <div v-if="show" class="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center h-screen w-full bg-[rgba(0,0,0,0.3)]">
        <div @click="toggleModal" class="fixed left-0 right-0 top-0 bottom-0 -z-10 h-screen"></div>
        <div class="w-2/5 h-fit bg-white rounded-lg flex flex-col justify-between items-center pb-5 gap-4 overflow-hidden" v-if="!fullReport">
            <div class="bg-NeonBlue w-full flex flex-row justify-between items-center p-8 py-4 overflow-hidden">
                <h1 class="text-xl font-medium w-full text-left text-white">{{ results.assessmentName }} results :</h1>
                <button class="text-white text-lg font-semibold" @click="toggleModal"><font-awesome-icon :icon="['fas', 'times']" /></button>
            </div>
            <div class="w-full p-8 flex flex-col gap-2">
                <div v-for="(trait, index) in traitsArray" :key="trait.name" class="flex flex-row justify-between items-center w-full">
                    <h1>{{ trait.name }}</h1>
                    <div class="flex flex-col justify-between items-center">
                        <div v-if="index === 0" class="w-full flex justify-between items-center text-slate-700 text-sm font-light">
                            <h2>Very low</h2>
                            <h2>Very high</h2>
                        </div>
                        <div class="w-full flex justify-between items-center">
                            <span class="w-14 h-10 border border-neutral-200" :class="trait.degree === 'Very Low' ? 'bg-[#EA4745]' : 'bg-[#fff]'"></span>
                            <span class="w-14 h-10 border border-neutral-200" :class="trait.degree === 'Low' ? 'bg-[#ffa500]' : 'bg-[#fff]'"></span>
                            <span class="w-14 h-10 border border-neutral-200" :class="trait.degree === 'Medium' ? 'bg-[#ffff00]' : 'bg-[#fff]'"></span>
                            <span class="w-14 h-10 border border-neutral-200" :class="trait.degree === 'High' ? 'bg-[#46A997]' : 'bg-[#fff]'"></span>
                            <span class="w-14 h-10 border border-neutral-200" :class="trait.degree === 'Very High' ? 'bg-[#008000]' : 'bg-[#fff]'"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex felx-row justify-end items-center gap-2 w-full p-3 px-8">
                <button class="w-fit px-4 py-3 rounded text-base font-normal bg-gray-200 text-slate-700" @click="toggleModal">Close</button>
                <button class="w-fit px-4 py-3 rounded text-base font-normal bg-NeonBlue text-white" @click="fullReport = true">Full report</button>
            </div>
        </div>
        <div v-else class="w-3/5 h-[95vh] bg-white overflow-y-scroll overflow-x-hidden rounded-lg reportWrapper">
            <div class="bg-NeonBlue w-full text-[18px] flex flex-row justify-between items-center px-4 py-3 overflow-hidden">
                <h1 class="text-[18px] rounded-lg font-medium w-full text-left text-white">{{ results.assessmentName }} interpretations :</h1>
                <button class="text-white text-lg font-semibold" @click="fullReport = false"><font-awesome-icon :icon="['fas', 'times']" /></button>
            </div>
            <div class="px-8 py-6" v-for="(trait, index) in traitsScores" :key="index">
                <h1 class="text-3xl text-[18px] mb-3 font-medium">{{ trait.name }}</h1>
                <p class="mb-5 text-[12px]">{{ trait.definition }}</p>
                <h2 class="text-2xl mb-3 mt-3 text-[16px] font-medium">{{ trait.status }} in {{ trait.name }}</h2>
                <h3 class="text-[14px]">Description</h3>
                <p class="mb-5 text-[12px]">{{ trait.description }}</p>
                <h3 class="text-[14px]">In personal relationships</h3>
                <p class="mb-5 text-[12px]">{{ trait.relationship }}</p>
                <h3 class="text-[14px]">At work</h3>
                <p class="mb-5 text-[12px]">{{ trait.atWork }}</p>
                <h3 class="text-[14px]">Their best attributes</h3>
                <p class="mb-5 text-[12px]">{{ trait.attributes }}</p>
                <h3 class="text-[14px]">Their main challenges</h3>
                <p class="mb-5 text-[12px]">{{ trait.challenges }}</p>
                <h3 class="text-[14px]">Tips for communicating and working with people {{ trait.status }} in {{ trait.name }}</h3>
                <p class="mb-5 text-[12px]">{{ trait.tips }}</p>
                <h3 class="text-[14px]">Discussion points</h3>
                <p class="mb-5 text-[12px]">{{ trait.discussion }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "BigFive",
    props: ["show", "toggleModal", "results"],
    computed: {
        traitsArray() {
            const keys = Object.keys(this.results.personalityResults);
            return keys.map((key) => {
                return { name: key, degree: this.results.personalityResults[key] };
            });
        },
        traitsScores() {
            // Convert traitsData object into an array of objects with embedded attributes
            return Object.keys(this.interpretations).map((traitName) => {
                const trait = this.interpretations[traitName];
                return {
                    name: traitName,
                    definition: trait.definition,
                    status: trait?.scoreData?.status,
                    description: trait.scoreData?.description,
                    relationship: trait.scoreData?.relationship,
                    atWork: trait.scoreData?.at_work,
                    attributes: trait.scoreData?.attributes,
                    challenges: trait.scoreData?.challenges,
                    tips: trait.scoreData?.tips,
                    discussion: trait.scoreData?.discussion,
                };
            });
        },
    },
    mounted() {
        axios
            .post(`https://server.go-platform.com/interpretations/scores/${this.results.assessmentName}`, { traitScores: this.results.personalityResults })
            .then((res) => (this.interpretations = res.data))
            .then(() => console.log("traits scores", this.interpretations))
            .catch((err) => console.log(err));
    },
    data() {
        return {
            interpretations: {},
            fullReport: false,

            colors: {
                "Very Low": "#EA4745",
                Low: "#ffa500",
                Medium: "#FFFF00",
                High: "#46A997",
                "Very High": "#008000",
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.color {
    background: #2196f3;
}

h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

p {
    font-size: 0.9rem;
}

.reportWrapper::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.reportWrapper::-webkit-scrollbar-track {
    background-color: #2195f31a;
    /* Color of the scrollbar track */
    /* Rounded corners */
}

.reportWrapper::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.reportWrapper::-webkit-scrollbar-thumb:hover {
    // background-color: #2195f328;
    /* Color of the scrollbar thumb on hover */
}
</style>
