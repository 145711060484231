<template>
    <div class="bg-[#F8F8FA] w-full pt-20">
        <h3 class="uppercase text-[#5d5fef] text-[16px] font-[700]">Candidate Report card</h3>
        <h1 class="text-[#292A2D] text-[38px] font-[600] my-6">Dig deeper and make informed decisions</h1>
        <p class="text-[#5A6074] text-[16px] my-4 w-[50%] mx-[25%]">
            See who can actually do the job in real time, create efficiency through automation and quickly surface the most qualified candidates based on how they perform job-related tasks.
        </p>
        <div class="w-[80%] mx-[10%] mt-[5%]">
            <img loading="lazy" decoding="async" src="../assets/Images/candidate_evaluation.png" alt="" class="w-full border-2 rounded border-[#D9D9D9] aspect-auto shadow-card" />
        </div>
    </div>
</template>

<script>
export default {
    name: "CandidateReportCard",
};
</script>
