<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgColor" />
    <table>
        <thead>
            <tr>
                <th>{{ $t("Assessment") }}</th>
                <th>{{ $t("Score") }}</th>
                <th>{{ $t("Time") }}</th>
                <th>{{ $t("Status") }}</th>
                <th>{{ $t("Candidates") }}</th>
            </tr>
        </thead>

        <tbody v-if="this.Store.getProjects?.length > 0">
            <tr v-for="archivesAssessmentData in this.Store.getProjects?.slice(0, 7)" v-bind:key="archivesAssessmentData.id">
                <td>
                    <span>{{ archivesAssessmentData.name.charAt(0) }}</span>
                    {{ archivesAssessmentData.name }}
                </td>
                <td>
                    <input
                        type="range"
                        :value="archivesAssessmentData.min_score ? archivesAssessmentData.min_score : archivesAssessmentData.recommanded_score"
                        disabled
                        id="success-slider"
                        :style="{
                            background: `linear-gradient(90deg, #2196f3 ${archivesAssessmentData.min_score ? archivesAssessmentData.min_score : archivesAssessmentData.recommanded_score}%, #FFF ${
                                archivesAssessmentData.min_score ? archivesAssessmentData.min_score : archivesAssessmentData.recommanded_score
                            }%)`,
                        }"
                    />
                </td>
                <td>1h:20min:15s</td>
                <td>
                    <label class="switch">
                        <input
                            type="checkbox"
                            :checked="archivesAssessmentData.project_status === 'Active' ? true : false"
                            @change="
                                () => {
                                    archivesAssessmentData.project_status === 'Active' ? (archivesAssessmentData.project_status = 'archived') : (archivesAssessmentData.project_status = 'Active');
                                    switchStatus(archivesAssessmentData._id);
                                }
                            "
                        />

                        <!-- <span class="slider round"></span> -->
                        <!-- <span class="custom-style round" v-if="archivesAssessmentData.passed"></span> -->
                        <span
                            class="slider round"
                            :class="{
                                'custom-style': archivesAssessmentData.project_status === 'Active',
                            }"
                        ></span>
                    </label>
                </td>
                <td>12</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="5" style="text-align: center; cursor: pointer" @click="toggleAllAssessmentsArchive">
                    {{ $t("Show all assessments") }}
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import { useStore } from "../store/index.js";
import ToastNotification from "@/components/ToastNotification";
import axios from "axios";

export default {
    components: {
        ToastNotification,
    },
    name: "ArchivedAssessments",
    props: {
        archivesAssessmentsData: Array,
        toggleAllAssessmentsArchive: Function,
    },
    data() {
        return {
            message: "",
            isVisible: false,
            bgColor: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        switchStatus(projectId) {
            console.log({ projectId });
            if (projectId) {
                let data = JSON.stringify({
                    id: projectId,
                });

                let config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/projects/switchStatus`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };
                axios
                    .request(config)
                    .then((res) => {
                        console.log(res);
                        this.message = `Project switched to ${res.data.project_status === "Active" ? "Active" : "archived"} successfully`;
                        this.isVisible = true;
                        this.bgColor = "green";
                        setTimeout(() => {
                            // this.$router.go();
                            this.isVisible = false;
                        }, 5000);
                        // this.$emit("archiveProject", this.project._id);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.message = "Project name does not match";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
    },
    async mounted() {
        await this.Store.fetchProjects();
    },
};
</script>

<style scoped lang="scss">
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    padding: 0 1px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.custom-style:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    // left: 2px;
    // right: 50px;
    // bottom: 3px;
    top: 14%;
    // transform: translateY(-20%);
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 80%;
    height: 10px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    margin-left: -2px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}

table {
    width: 100%;
    border-radius: 20px;
    border-collapse: collapse;

    thead {
        background-color: #f9fafd;

        & > :first-child {
            text-align: left;
        }

        th {
            padding: 1rem;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            color: #4d5969;
        }
    }

    tbody {
        background-color: #fff;

        tr {
            & > :first-child {
                text-align: left;

                span {
                    color: #2196f3;
                    background-color: #e9e3ff;

                    padding: 4% 5%;
                    border-radius: 50%;
                }
            }

            td {
                padding: 1rem;
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                color: #4d5969;
                border: none;
            }
        }
    }

    tfoot {
        background-color: #f9fafd;

        tr {
            td {
                padding: 1rem;
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                color: #4d5969;
                border: none;
            }
        }
    }
}
</style>
