<template>
    <div class="row transition duration-300 ease-in-out hover:text-[#2196f3] p-2 rounded-[5px]" @click="toggleFeedBack">
        <!-- <img loading="lazy"  decoding="async" class="profile-image" src="../assets/Images/icons/Avatar.svg" alt="cadidate-profile" /> -->
        <div class="info flex items-center gap-[10px] cursor-pointer border-b-2 border-sky-200 py-2">
            <span class="candidate-name w-[75%]">{{ candidateRating?.candidateEmail }}</span> <br />
            <div class="score w-[10%] h-[40px]" :class="scoreAccepted() ? 'aquired' : 'declined'">{{ candidateRating.rating }}/5</div>
        </div>
        <div :style="`transition: all .5s ease ;transition-delay: 0.1s ;height: ${showFeedback ? '100px' : '0px'}; background-color: ${showFeedback ? '#fff' : 'none'}; overflow: hidden`">
            <span class="timestamp" v-if="showFeedback">{{ candidateRating?.feedback }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CandidatesRating",
    props: {
        candidateRating: {
            type: Object,
        },
    },
    data() {
        return {
            showFeedback: false,
        };
    },
    methods: {
        toggleFeedBack() {
            if (this.candidateRating?.feedback) {
                this.showFeedback = !this.showFeedback;
            }
        },

        scoreAccepted: function () {
            return true;
        },
        calculateScore() {
            let score = 0;
            this.candidate?.results.forEach((element) => {
                score += (element.totalPoints * 100) / element.quesionsNbr;
            });
            const averageScore = score / this.candidate?.results.length;
            const roundedScore = averageScore.toFixed(2);
            return roundedScore;
        },
        getDateAndTime(dateString) {
            const date = new Date(dateString);

            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${parseInt(hours) + 1}:${minutes}:${seconds}`;
            return formattedDateTime;
        },
    },
};
</script>

<style scoped>
.row {
    /* display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 10px;
    height: 100%; */
}

.row:hover {
    /*  box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);*/
}

.profile-image {
    margin-right: 16px;
}

.candidate-name {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 14px;
}

.timestamp {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 12px;
    color: #a3aed0;
    height: 1000px;
    transition: height 4.3s ease;
}

.score {
    margin-left: auto;
    justify-self: end;
    padding: 4px 8px;
    border-radius: 7px;
    color: #05cd99;
}

.aquired {
    background: rgba(5, 205, 153, 0.1);
    color: #05cd99;
}

.declined {
    background: rgba(238, 93, 80, 0.1);
    color: #ee5d50;
}

.score {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
