<template>
    <div>
        <button class="carousel-control left" @click="$emit('prev')">{{ $t("Previous") }}</button>
        <button class="carousel-control right" @click="$emit('next')">{{ $t("Next") }}</button>
    </div>
</template>

<script>
export default {
    name: "CarouselControls",
};
</script>

<style scoped>
.carousel-control {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    display: inline-block;
    position: absolute;
    height: 50px;
    width: 70px;
    top: calc(50% - 25px);
    color: #f3f3f3;
    cursor: pointer;
}
.left {
    left: 0;
}
.right {
    right: 0;
}
</style>
