<template>
    <div v-if="show" class="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center h-screen w-full bg-[rgba(0,0,0,0.3)]">
        <div @click="toggleModal" class="fixed left-0 right-0 top-0 bottom-0 -z-10 h-screen"></div>
        <div class="w-1/2 h-4/5 p-10 rounded-lg bg-white flex flex-col justify-between items-center">
            <div>
                <h1 class="text-lg font-semibold w-full text-left text-slate-800">Device and location</h1>
                <p class="text-sm font-light w-full text-left text-slate-700">
                    We register the candidate’s type of device used for the assessment, as well as the geographic location, based on their IP address.
                </p>
            </div>
            <div>
                <h1 class="text-lg font-semibold w-full text-left text-slate-800">Filled out only once from IP address</h1>
                <p class="text-sm font-light w-full text-left text-slate-700">
                    Using the IP address, we check if candidates fill out the assessment only once (with a public link to the assessment, candidates could use multiple email addresses to take repeated
                    attempts at the assessment).
                </p>
            </div>
            <div>
                <h1 class="text-lg font-semibold w-full text-left text-slate-800">Webcam/front camera enabled</h1>
                <p class="text-sm font-light w-full text-left text-slate-700">
                    When candidates start their assessment, we ask them to activate their webcam/camera. This allows us to capture images of your candidates every 30 seconds. This way you can see if
                    the same (and only one) person has worked on the assessment.
                </p>
            </div>
            <div>
                <h1 class="text-lg font-semibold w-full text-left text-slate-800">Full-screen mode always active</h1>
                <p class="text-sm font-light w-full text-left text-slate-700">
                    For candidates that use a desktop or laptop, we also activate full-screen mode to ensure candidates don’t browse the internet to look up answers. While we cannot prevent that
                    candidates deactivate full-screen mode, we can detect if they did. It indicates a potential violation.
                </p>
            </div>
            <div>
                <h1 class="text-lg font-semibold w-full text-left text-slate-800">Mouse always in assessment window</h1>
                <p class="text-sm font-light w-full text-left text-slate-700">
                    We can detect if the mouse has always been on the test window. Candidates that have two screens could otherwise still have another window open to browse the internet.
                </p>
            </div>
            <button @click="toggleModal" class="w-fit h-fit px-6 py-2 bg-[#2371b631] text-center text-lg font-semibold ml-auto rounded text-slate-800">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AntiCheatingPolicy",
    props: {
        show: Boolean,
        toggleModal: Function,
    },
};
</script>

<style lang="scss" scoped></style>
