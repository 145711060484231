<template>
    <div class="carousel-indicators">
        <button class="carousel-indicators-item" v-for="(item, index) in total" :class="{ active: currentIndex === index }" @click="$emit('switch', index)" :key="index"></button>
    </div>
</template>

<script>
export default {
    name: "CarouselIndicators",
    props: ["total", "currentIndex"],
};
</script>

<style scoped>
.carousel-indicators {
    z-index: 2;
    width: 73%;
    display: flex;
    justify-content: center;
}
.carousel-indicators-item {
    width: 15px;
    height: 15px;
    border: none;
    background: #fff;
    opacity: 0.5;
    margin: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}
.active {
    opacity: 1;
}
</style>
